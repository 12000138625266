import IconTennis from '../assets/icons/IconTennis';
import IconPadel from '../assets/icons/IconPadel';
import IconSquash from '../assets/icons/IconSquash';
import React from 'react';
import { Stack, Typography } from '@mui/material';

export function RenderSportIcon({sportName, color}) {
  switch (sportName) {
    case 'Tennis' :
      return <IconTennis fill={color}/>;
    case 'Padel' :
      return <IconPadel fill={color}/>;
    case 'Squash' :
      return <IconSquash fill={color}/>;
    default:
      return;
  }
}

export const getTableValueInMobileView=(label,value,content,props)=>{

  const labelWeight = props?.labelWeight

  return(
    <Stack
      width="100%"
      direction={"row"}
      justifyContent={content ?? "space-between"}
      flexWrap={'wrap'}
    >
      {label && <Typography fontWeight={labelWeight ?? 500}>{`${label} ${value? ':':''}`}</Typography>}
      <Typography>{value}</Typography>
    </Stack>
  )
}

export const hideStringLengthGT25=(value)=>{

  const converted = value?.slice(0,24);
  return converted + '...'

}
export function isTestClub(clubId){

  return clubId == 21687911;
} 

export function isOwnUser(){

  const USER = localStorage?.getItem('user');
  
  if(USER)
    return JSON.parse(USER)?.userObj?.email == 'hk@moonlabor.com';
} 